import { CurrentUser, UserRegistrationStep } from 'types';
import { isEmpty } from 'utils/toggleParams';
import { NextBackgroundImage, NextIcon } from 'components/NextImage';
import { NextLink } from 'components/NextLink';
import { SideMenu, SideMenuItem } from 'components/SideMenu';

import styles from './MainMenu.module.scss';
import { APPSTORE_APP_LINK, GOOGLE_PLAY_APP_LINK } from '../../consts';
import Image from 'next/legacy/image';
import { NextIconWithNotification } from '../NextImage/NextIconWithNotification';

export type MainMenuProps = {
  active?: boolean;
  isSignUpIncomplete: boolean | UserRegistrationStep;
  customClass?: string;
  items: Array<SideMenuItem>;
  setActive?: (active: boolean) => void;
  user?: CurrentUser | null;
};

export const MainMenu = (props: MainMenuProps) => {
  const { user, isSignUpIncomplete } = props;

  const renderLinks = () => (
    <div className={styles.firstLine}>
      <NextLink href="/sign-in" title="Sign In" className={styles.firstLine} />
      <p>|</p>
      <NextLink href="/sign-up" title="Sign Up" />
    </div>
  );

  const renderUserInfo = () => renderHeaderContent(user?.photo || null, user?.full_name || '', user?.username || '');

  const renderSignOptions = () => renderHeaderContent(null, renderLinks(), 'Authorize to get more opportunities');

  const renderHeaderContent = (avatar: string | null, topLine: JSX.Element | string, bottomLine: string) => (
    <div className={styles.headerContent}>
      <div className={styles.headerAvatar}>
        {avatar ? (
          <NextBackgroundImage customClass={styles.headerAvatar} src={avatar} />
        ) : (
          <NextIcon src={'/icons/UserWhite.svg'} />
        )}
      </div>
      <div>
        <div className={styles.firstLine}>{topLine}</div>
        <p className={styles.secondLine}>{bottomLine}</p>
      </div>
    </div>
  );

  const renderBefore = () => (
    <NextLink href={isSignUpIncomplete ? '/sign-up?redirect=/item-management' : '/item-management'}>
      <div className={styles.heartUA}>
        <div className={styles.heartUALeft}>
          <NextIconWithNotification src="/icons/design/PlusCircleBlack.svg" alt="Add Gear" />
          <p>Post Gear</p>
        </div>
      </div>
    </NextLink>
  );

  const renderAfter = () => (
    <div className={styles.menuAfter}>
      <p>Download our App</p>
      <div className={styles.afterIcons}>
        <NextLink href={APPSTORE_APP_LINK} newTab={true} className={styles.icon}>
          <Image alt="App Store" src="/App_Store_Badge.svg" layout="fill" objectFit="contain" />
        </NextLink>
        <NextLink href={GOOGLE_PLAY_APP_LINK} newTab={true} className={styles.icon}>
          <Image alt="Google Pay" src="/Google_Play_Store.svg" layout="fill" objectFit="contain" />
        </NextLink>
      </div>
    </div>
  );

  return (
    <SideMenu
      headerContent={isEmpty(user) ? renderSignOptions() : renderUserInfo()}
      before={renderBefore()}
      after={renderAfter()}
      {...props}
    />
  );
};
